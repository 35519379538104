import { getAllReports } from "apis/driverClient";
import { useEffect, useState } from "react";
import DataTotalInfo from "./Components/DataTotalInfo";
import { enGB } from "date-fns/locale";
import { DateRange, DateRangePicker } from "react-date-range";

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import ArrowDownwardIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowUpwardIcon from '@mui/icons-material/KeyboardArrowUp';
import { convertTZ } from "utils/utils";

const { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, styled, Box, Button, Typography, ButtonGroup, ButtonBase } = require("@mui/material");

const BoxSelector = styled(Box)(() => ({
    width: "full",
    display: "flex",
    flexDirection: "column",
    alignItems: "end",
    gap: "20px"
}));

const AnalyzeData = ({ storeData }) => {
    const usDate = convertTZ(new Date(), "America/New_York");
    const [reportData, setReportData] = useState();
    const [npiKey, setNpiKey] = useState('');
    const [showDateRange, setShowDateRange] = useState(false);
    const [dateRange, setDateRange] = useState({
        startDate: usDate,
        endDate: usDate, //default today
        key: "selection"
    })

    const [dateSortStatus, setDateSortStatus] = useState(false);
    const [driverSortStatus, setDriverSortStatus] = useState(false);
    const [elapsedTimeSortStatus, setElapsedTimeSortStatus] = useState(true);
    const [dropsSortStatus, setDropsSortStatus] = useState(true);
    const [milesTraveledSortStatus, setDMilesTraveledSortStatus] = useState(true);
    const [failedSortStatus, setFailedSortStatus] = useState(true);
    //If true, sort desc, false sort asc

    const [arrowStyle, setArrowStyle] = useState(<ArrowDownwardIcon />);
    const [sortedColumnId, setSortedColumnId] = useState(1);
    const [selectedDateRange, setSelectedDateRange] = useState({
        startDate: usDate,
        endDate: usDate, //default today
        key: "selection"
    })

    function fetchDate() {
        if (localStorage.getItem("analyzeDataDate")) {
            const localStorageDate = localStorage.getItem("analyzeDataDate");
            const result = JSON.parse(localStorageDate);
            const startDateData = new Date(result.startDate);
            const endDateData = new Date(result.endDate);
            setDateRange(prevData => ({
                ...prevData,
                endDate: endDateData
            }));
            setDateRange(prevData => ({
                ...prevData,
                startDate: startDateData
            }));
        }
    }

    useEffect(() => {
        fetchDate();
    }, []);

    useEffect(() => {
        localStorage.setItem("analyzeDataDate", JSON.stringify(dateRange));
    }, [dateRange]);

    useEffect(() => {
        setNpiKey(storeData.npi);
    }, [storeData])

    const reportKey = {
        "npi": npiKey,
        "from": `${filterDate(dateRange.startDate)} 00:00:00`,
        "to": `${filterDate(dateRange.endDate)} 23:59:59`
    };

    async function fetchReportData() {
        if (npiKey && dateRange) {
            const response = await getAllReports(reportKey.npi, reportKey.from, reportKey.to);
            return response;
        }
        return null
    }

    async function sortData() {
        let fetchedData = await fetchReportData();
        if (fetchedData) {
            fetchedData.deliveries = sortByDateOnFirstTime(fetchedData.deliveries);
            setReportData(fetchedData);
        }
    }

    useEffect(() => {
        setTimeout(() => {
            sortData();
        }, 500)
    }, []);

    function filterDate(date) {
        if (!(date instanceof Date)) {
            date = new Date(date);
        }

        if (isNaN(date)) {
            throw new Error("Invalid date");
        }

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
        return `${month}/${day}/${year}`;
    }

    function formatDateTime(dateTimeStr) {
        const date = new Date(dateTimeStr);
        const options = {
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
            timeZone: 'America/New_York'
        };

        return date.toLocaleString('en-US', options);
    }

    const handleShowDateRangeClick = () => {
        setShowDateRange(!showDateRange);
    }

    useEffect(() => {
        setDateRange(selectedDateRange);
    }, [selectedDateRange]);

    function sortByDateOnFirstTime(items) {
        return items.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    }

    function sortByDate() {
        if (dateSortStatus) {
            const sortedDeliveriesByDate = reportData.deliveries.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setReportData({
                ...reportData,
                deliveries: sortedDeliveriesByDate
            })
            setDateSortStatus(false);
            setArrowStyle(<ArrowDownwardIcon />)
        } else {
            const sortedDeliveriesByDate = reportData.deliveries.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
            setReportData({
                ...reportData,
                deliveries: sortedDeliveriesByDate
            })
            setDateSortStatus(true);
            setArrowStyle(<ArrowUpwardIcon />)
        }
        setSortedColumnId(1);
    }

    function sortByDriver() {
        if (driverSortStatus) {
            const sortedDeliveriesByDriver = reportData.deliveries.sort((a, b) => b.driver_name.localeCompare(a.driver_name));
            setReportData({
                ...reportData,
                deliveries: sortedDeliveriesByDriver
            })
            setDriverSortStatus(false);
            setArrowStyle(<ArrowDownwardIcon />)
        } else {
            const sortedDeliveriesByDriver = reportData.deliveries.sort((a, b) => a.driver_name.localeCompare(b.driver_name));
            setReportData({
                ...reportData,
                deliveries: sortedDeliveriesByDriver
            })
            setDriverSortStatus(true);
            setArrowStyle(<ArrowUpwardIcon />)
        }
        setSortedColumnId(2);
    }

    function sortByElapsedTime() {
        if (elapsedTimeSortStatus) {
            const sortedDeliveriesByElapsedTime = reportData.deliveries.sort((a, b) => parseFloat(b.elapsed_time) - parseFloat(a.elapsed_time));
            setReportData({
                ...reportData,
                deliveries: sortedDeliveriesByElapsedTime
            })
            setElapsedTimeSortStatus(false);
            setArrowStyle(<ArrowDownwardIcon />)
        } else {
            const sortedDeliveriesByElapsedTime = reportData.deliveries.sort((a, b) => parseFloat(a.elapsed_time) - parseFloat(b.elapsed_time));
            setReportData({
                ...reportData,
                deliveries: sortedDeliveriesByElapsedTime
            })
            setElapsedTimeSortStatus(true);
            setArrowStyle(<ArrowUpwardIcon />)
        }
        setSortedColumnId(3);
    }

    function sortByDrops() {
        if (dropsSortStatus) {
            const sortedDeliveriesByDrops = reportData.deliveries.sort((a, b) => parseInt(b.drops) - parseInt(a.drops));
            setReportData({
                ...reportData,
                deliveries: sortedDeliveriesByDrops
            })
            setDropsSortStatus(false);
            setArrowStyle(<ArrowDownwardIcon />)
        } else {
            const sortedDeliveriesByDrops = reportData.deliveries.sort((a, b) => parseInt(a.drops) - parseInt(b.drops));
            setReportData({
                ...reportData,
                deliveries: sortedDeliveriesByDrops
            })
            setDropsSortStatus(true);
            setArrowStyle(<ArrowUpwardIcon />)
        }
        setSortedColumnId(4);
    }

    function sortByMilesTraveled() {
        if (milesTraveledSortStatus) {
            const sortedDeliveriesByMilesTraveled = reportData.deliveries.sort((a, b) => parseFloat(b.miles) - parseFloat(a.miles));
            setReportData({
                ...reportData,
                deliveries: sortedDeliveriesByMilesTraveled
            })
            setDMilesTraveledSortStatus(false);
            setArrowStyle(<ArrowDownwardIcon />)
        } else {
            const sortedDeliveriesByMilesTraveled = reportData.deliveries.sort((a, b) => parseFloat(a.miles) - parseFloat(b.miles));
            setReportData({
                ...reportData,
                deliveries: sortedDeliveriesByMilesTraveled
            })
            setDMilesTraveledSortStatus(true);
            setArrowStyle(<ArrowUpwardIcon />)
        }
        setSortedColumnId(5);
    }

    function sortByFailed() {
        if (failedSortStatus) {
            const sortedDeliveriesByFailed = reportData.deliveries.sort((a, b) => parseInt(b.total_fail) - parseInt(a.total_fail));
            setReportData({
                ...reportData,
                deliveries: sortedDeliveriesByFailed
            })
            setFailedSortStatus(false);
            setArrowStyle(<ArrowDownwardIcon />)
        } else {
            const sortedDeliveriesByFailed = reportData.deliveries.sort((a, b) => parseInt(a.total_fail) - parseInt(b.total_fail));
            setReportData({
                ...reportData,
                deliveries: sortedDeliveriesByFailed
            })
            setFailedSortStatus(true);
            setArrowStyle(<ArrowUpwardIcon />)
        }
        setSortedColumnId(6);
    }

    function handleDateChange(ranges) {
        setDateRange(ranges.selection)
    }

    function handleDateCancel() {
        setShowDateRange(false);
        setDateRange(selectedDateRange);
    }

    const handleDateConfirmation = () => {
        sortData();
        setShowDateRange(false);
        setSelectedDateRange(dateRange);
    }

    return (
        <>
            <BoxSelector>
                <Button variant="outlined" onClick={() => handleShowDateRangeClick()}>{`${filterDate(selectedDateRange.startDate)} to ${filterDate(selectedDateRange.endDate)}`}</Button>
                {showDateRange && (
                    window.innerWidth <= 1000 ? (
                        <>
                            <div className="popup-overlay"></div>
                            <Box
                                style={{
                                    zIndex: 9999,
                                    position: 'absolute',
                                    marginTop: "40px",
                                    right: '0',
                                    background: 'white',
                                }}>
                                <DateRange
                                    ranges={[dateRange]}
                                    onChange={handleDateChange}
                                    locale={enGB}
                                    className="mb-4"
                                    showSelectionPreview={true}
                                    moveRangeOnFirstSelection={false}
                                    months={1}
                                    direction="horizontal"
                                />
                                <Box
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                    }}
                                >
                                    <Button onClick={() => handleDateCancel()}>Cancel</Button>
                                    <Button onClick={() => handleDateConfirmation()}>OK</Button>
                                </Box>
                            </Box>
                        </>
                    ) : (
                        <Box
                            className="pc-view-date-range-picker"
                            style={{
                                zIndex: 999,
                                position: 'absolute',
                                marginTop: "40px",
                                background: 'white',
                            }}>
                            <DateRangePicker
                                ranges={[dateRange]}
                                onChange={handleDateChange}
                                locale={enGB}
                                className="mb-4"
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                months={2}
                                direction="horizontal"
                            />
                            <Box
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}
                            >
                                <Button onClick={() => handleDateCancel()}>Cancel</Button>
                                <Button onClick={() => handleDateConfirmation()}>OK</Button>
                            </Box>
                        </Box>
                    )
                )}
                {/* <LocalizationProvider dateAdapter={AdapterDateFns} locale={enGB}>
                    <DateRangePicker
                        slotProps={{
                            shortcuts: {
                                items: shortcutsItems,
                            },
                            actionBar: { actions: [] },
                        }}
                        value={dateRange}
                        onChange={handleSelect}
                        open={showDateRange}
                        onOpen={onOpenDateRangePicker}
                        onClose={onCloseDateRangePicker}
                        renderInput={(startProps, endProps) => {
                            const { inputProps: startInputProps } =
                                startProps || {};
                            const { value: startValue } = startInputProps || {};
                            const { inputProps: endInputProps } = endProps || {};
                            const { value: endValue } = endInputProps || {};
                            let inputValue = "";

                            if (startValue !== "" && endValue !== "") {
                                inputValue = `${startValue} - ${endValue}`;
                            }

                            return (
                                <FilterPaper
                                    component="form"
                                    sx={{
                                        p: "2px 4px",
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                    style={{
                                        width: "100%",
                                    }}
                                >
                                    <BoxInputSearch>
                                        {inputValue !== "" && (
                                            <IconButton
                                                sx={{ p: "10px" }}
                                                onClick={onClickClearStartAndEndTime}
                                            >
                                                <CloseBlack />
                                            </IconButton>
                                        )}

                                        <InputBase
                                            sx={{ ml: 1 }}
                                            ref={startProps.inputRef}
                                            {...startProps.inputProps}
                                            inputProps={{
                                                placeholder: "Filter by date",
                                                value: inputValue,
                                            }}
                                            fullWidth
                                            readOnly
                                        />
                                        <IconButton
                                            sx={{ p: "10px" }}
                                            onClick={onOpenDateRangePicker}
                                        >
                                            <DownSVG />
                                        </IconButton>
                                    </BoxInputSearch>
                                </FilterPaper>
                            );
                        }}
                        className="mb-4"
                        sx={{
                            zIndex: 999,
                            position: 'absolute'
                        }}
                    />
                </LocalizationProvider> */}
            </BoxSelector>

            <div className="data-total-info">
                {/* <DataBox style={{ backgroundColor: "#d3e2ff" }}>{reportData?.total_days}</DataBox>
                <DataBox style={{ backgroundColor: "#dfffcf" }}>{reportData?.total_completed}</DataBox>
                <DataBox style={{ backgroundColor: "#f1d8e0" }}>{reportData?.total_failed}</DataBox> */}

                <DataTotalInfo
                    label={'Day Operated'}
                    data={reportData?.total_days}
                    infoName={'Days'}
                    backgroundColor={'#d3e2ff'}
                    compareData={reportData?.compare_days}
                />
                <DataTotalInfo
                    label={'Completed'}
                    data={reportData?.total_completed}
                    infoName={'Deliveries'}
                    backgroundColor={'#dfffcf'}
                    compareData={reportData?.compare_completed}
                />
                <DataTotalInfo
                    label={'Failed'}
                    data={reportData?.total_failed}
                    infoName={'Deliveries'}
                    backgroundColor={'#f1d8e0'}
                    compareData={reportData?.compare_failed}
                />
            </div>
            <TableContainer component={Paper} style={{ marginTop: "25px" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ color: '#041967' }} onClick={() => sortByDate()}>
                                <div style={{ display: 'flex' }}>
                                    <Typography>Date</Typography>
                                    <div>{sortedColumnId === 1 ? (
                                        <div>{arrowStyle}</div>
                                    ) : (
                                        <div style={{ opacity: "0.25" }}><ArrowDownwardIcon /></div>
                                    )}</div>                                </div>
                            </TableCell>
                            <TableCell style={{ color: '#041967' }} onClick={() => sortByDriver()}>
                                <div style={{ display: 'flex' }}>
                                    <Typography>Driver</Typography>
                                    <div>{sortedColumnId === 2 ? (
                                        <div>{arrowStyle}</div>
                                    ) : (
                                        <div style={{ opacity: "0.25" }}><ArrowDownwardIcon /></div>
                                    )}</div>                                </div>
                            </TableCell>
                            <TableCell style={{ color: '#041967' }} onClick={() => sortByElapsedTime()}>
                                <div style={{ display: 'flex' }}>
                                    <Typography>Elapsed Time</Typography>
                                    <div>{sortedColumnId === 3 ? (
                                        <div>{arrowStyle}</div>
                                    ) : (
                                        <div style={{ opacity: "0.25" }}><ArrowDownwardIcon /></div>
                                    )}</div>                                </div>
                            </TableCell>
                            <TableCell style={{ color: '#041967' }} onClick={() => sortByDrops()}>
                                <div style={{ display: 'flex' }}>
                                    <Typography>Drops</Typography>
                                    <div>{sortedColumnId === 4 ? (
                                        <div>{arrowStyle}</div>
                                    ) : (
                                        <div style={{ opacity: "0.25" }}><ArrowDownwardIcon /></div>
                                    )}</div>                                </div>
                            </TableCell>
                            <TableCell style={{ color: '#041967' }} onClick={() => sortByMilesTraveled()}>
                                <div style={{ display: 'flex' }}>
                                    <Typography>Miles Traveled</Typography>
                                    <div>{sortedColumnId === 5 ? (
                                        <div>{arrowStyle}</div>
                                    ) : (
                                        <div style={{ opacity: "0.25" }}><ArrowDownwardIcon /></div>
                                    )}</div>                                </div>
                            </TableCell>
                            <TableCell style={{ color: '#041967' }} onClick={() => sortByFailed()}>
                                <div style={{ display: 'flex' }}>
                                    <Typography>Failed</Typography>
                                    <div>{sortedColumnId === 6 ? (
                                        <div>{arrowStyle}</div>
                                    ) : (
                                        <div style={{ opacity: "0.25" }}><ArrowDownwardIcon /></div>
                                    )}</div>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {reportData?.deliveries?.map((data) => (
                            <TableRow key={data.id}>
                                <TableCell>{formatDateTime(data.created_at)}</TableCell>
                                <TableCell>{data.driver_name}</TableCell>
                                <TableCell>{data.elapsed_time}</TableCell>
                                <TableCell>{data.drops}</TableCell>
                                <TableCell>{data.miles}</TableCell>
                                <TableCell>{data.total_fail}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default AnalyzeData;