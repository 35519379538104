export const API_URL = process.env.REACT_APP_API_URL;

export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

export const GOOGLE_MAP_API_KEY_2 = process.env.REACT_APP_GOOGLE_MAP_API_KEY_TMP;

export const DRIVER_API_URL = process.env.REACT_APP_DRIVER_API_URL;

export const API_PREFIX = "api";

export const API_V1 = "v1";

export const PREFIX_PHARMACY = "pharmacy";

export const PREFIX_WORKERS = "workers";

export const PREFIX_ORDERS = "orders";

export const PREFIX_PRINT_LABEL = "print_label";

export const PREFIX_STORES = "stores";

export const PREFIX_APIKEYS = "apikeys";

export const PREFIX_MESSAGES = "message_threads";

export const PREFIX_MESSAGES_DETAIL = "messages";

export const PREFIX_RECIPIENTS = "recipients";

export const PREFIX_PACKAGES = "packages";

export const PREFIX_ESTIMATIONS = "estimations";

export const PREFIX_REFRESH_ESTIMATIONS = "refresh_estimations";

export const PREFIX_SELECT_ESTIMATIONS = "select_estimation";

export const PREFIX_NOTIFICATIONS = "notifications";

export const PREFIX_ACTIVE_ORDERS = "active_orders";
export const PREFIX_ACTIVE_OTC_ORDERS = "active_otc_orders";

export const PREFIX_BATCH_ORDERS = "batch_orders";

export const PREFIX_BATCH_ORDER_DETAILS = "batch_order_details";

export const PREFIX_PRIORS = "prior_authorizations";

export const PREFIX_PRIORS_SEND = "send_prior_authorization";

export const PREFIX_PRIORS_COMPLETED = "completed_prior_authorization";

export const PREFIX_PRIORS_SCHEDULES = "schedules";

export const PREFIX_PRIORS_SCHEDULES_SETTING = "schedule_settings";

export const PREFIX_DOWNLOAD_TEMPLATE = "download_template";

export const PREFIX_SEND_FAX = "send_fax";

export const PREFIX_DOCTORS = "doctors";

export const PREFIX_INVOICE = "invoices";

export const PREFIX_IMPORTS = "imports";

export const PREFIX_DOCTORS_IMPORTS = "doctors_imports";


//tracking
export const HOME_PAGE = "HOME_WEB";
export const INVOICE_LIST_PAGE = "INVOICE_LIST_WEB";
export const REMOTE_STAFF_PAGE = "REMOTE_STAFF_WEB";
export const ORDERS_LIST_PAGE = "ORDERS_LIST_WEB";
export const ORDER_DETAIL_PAGE = "ORDER_DETAIL_WEB";
export const OTC_ORDERS_LIST_PAGE = "OTC_ORDERS_LIST_WEB";
export const OTC_ORDER_DETAIL_PAGE = "OTC_ORDER_DETAIL_WEB";
export const PATIENTS_LIST_PAGE = "PATIENTS_LIST_WEB";
export const PATIENTS_DETAIL_PAGE = "PATIENTS_DETAIL_WEB";
export const WORKERS_LIST_PAGE = "WORKERS_LIST_WEB";
export const WORKERS_DETAIL_PAGE = "WORKERS_DETAIL_WEB";
export const DOCTORS_LIST_PAGE = "DOCTORS_LIST_WEB";
export const DOCTORS_DETAIL_PAGE = "DOCTORS_DETAIL_WEB";
export const PROFILE_PAGE = "PROFILE_WEB";
export const PHARMACY_FAX_PAGE = "PHARMACY_FAX_WEB";
export const MESSAGES_PAGE = "MESSAGES_WEB";

export const ROUTE = {
  ROOT: {
    URL: "/",
    ICON: "home",
    NAME: "Home",
    TRACK: "HOME_WEB",
    HIDE: false,
  },
  DRIVER: {
    URL: "/drivers",
    ICON: "driver",
    NAME: "Drivers",
    TRACK: "DRIVER_WEB",
  },
  SIGN_IN: {
    URL: "/sign-in",
    ICON: "",
    NAME: "Sign In",
    TRACK: "SIGN_IN_WEB",
    HIDE: true,
  },
  SIGN_UP: {
    URL: "/sign-up",
    ICON: "",
    NAME: "Sign Up",
    TRACK: "SIGN_UP_WEB",
    HIDE: true,
  },
  RESET_PASSWORD: {
    URL: "/reset-password",
    ICON: "",
    NAME: "reset password",
    TRACK: "RESET_PASSWORD_WEB",
    HIDE: true,
  },
  ORDERS: {
    URL: "/orders",
    ICON: "orders",
    NAME: "Orders",
    TRACK: "ORDERS_LIST_WEB",
    HIDE: false,
  },
  OTC_ORDERS: {
    URL: "/otc-orders",
    ICON: "otc-orders",
    NAME: "OTC Orders",
    TRACK: "OTC_ORDERS_LIST_WEB",
  },
  VIRTUAL_STAFF: {
    URL: "/virtual-remote-staff",
    ICON: "invoice",
    NAME: "Remote Staff",
    TRACK: "REMOTE_STAFF_ORDERS_LIST_WEB",
    HIDE: false,
  },
  POST_JOB: {
    URL: "/post-job",
    ICON: "invoice",
    NAME: "POST JOB",
    TRACK: "POST_JOB",
    HIDE: false,
  },

  MESSAGES: {
    URL: "/messages",
    ICON: "messages",
    NAME: "Messages",
    TRACK: "MESSAGES_WEB",
    HIDE: false,
  },
  PATIENTS: {
    URL: "/patients",
    ICON: "patients",
    NAME: "Patients",
    TRACK: "PATIENTS_LIST_WEB",
    HIDE: false,
  },
  WORKERS: {
    URL: "/workers",
    ICON: "workers",
    NAME: "Workers",
    TRACK: "WORKERS_LIST_WEB",
    HIDE: false,
  },
  DOCTORS: {
    URL: "/doctors",
    ICON: "doctors",
    NAME: "Doctors",
    TRACK: "DOCTORS_LIST_WEB",
    HIDE: false,
  },
  PROFILE: {
    URL: "/profile",
    ICON: "profile",
    NAME: "Profile",
    TRACK: "PROFILE_WEB",
    HIDE: false,
  },
  PHARMACY_FAX: {
    URL: "/pharmacy-fax",
    ICON: "pharmacy",
    NAME: "Pharmacy Fax",
    TRACK: "PHARMACY_FAX_WEB",
    HIDE: false,
  },
  INVOICE: {
    URL: "/invoice",
    ICON: "invoice",
    NAME: "Client Invoicing",
    TRACK: "CLIENT_INVOICING_WEB",
    HIDE: false,
  },
  API_KEY_AND_SERVICES: {
    URL: "/api-key",
    ICON: "api-key",
    NAME: "Api Key",
    TRACK: "API_KEY_WEB",
    HIDE: false,
  },
  PAYMENT: {
    URL: "/payment",
    ICON: "payment",
    NAME: "Payment History",
    TRACK: "PAYMENT_WEB",
    HIDE: false,
  },
};

export const COLORS = {
  PRIMARY: {
    MAIN: "#041967",
  },
  BLUE: {
    MAIN: "#008DFF",
  },
  GREY: {
    MAIN: "#3B3B3B",
  },
  YELLOW: {
    MAIN: "#FFDE4A",
  },
  WHITE: {
    MAIN: "#FFFFFF",
  },
  RED: {
    MAIN: "#EF3900",
  },
  DEFAULT: {
    MAIN: "#FFFFFF",
  },
  DISABLED: {
    MAIN: "#F2F2F2",
  },
};

export const ORDERS_ROUTE = {
  ROOT: {
    URL: "/",
    ICON: "orders",
    NAME: "Orders",
    HIDE: false,
  },
  VIEW: {
    URL: "/:id",
    ICON: "create",
    NAME: "View order",
    HIDE: false,
  },
  CREATE: {
    URL: "/create",
    ICON: "create",
    NAME: "Create order",
    HIDE: false,
  },
  EDIT: {
    URL: "/:id/edit",
    ICON: "create",
    NAME: "Edit order",
    HIDE: false,
  },
  IMPORT: {
    URL: "/import",
    ICON: "import",
    NAME: "Import Orders",
    HIDE: false,
  },
  IMPORT_DETAIL: {
    URL: "/import/:id/detail",
    ICON: "import",
    NAME: "Import Details",
    HIDE: false,
  },
};
export const OTC_ORDERS_ROUTE = {
  ROOT: {
    URL: "/",
    ICON: "c",
    NAME: "OTC Orders",
    HIDE: false,
  },
};

export const PATIENTS_ROUTE = {
  ROOT: {
    URL: "/",
    ICON: "patients",
    NAME: "Patients",
    HIDE: false,
  },
};

export const PRIORS_ROUTE = {
  ROOT: {
    URL: "/",
    ICON: "priors",
    NAME: "Priors Authorization",
    HIDE: false,
  },
  VIEW: {
    URL: "/:id",
    ICON: "create",
    NAME: "View Prior",
    HIDE: false,
  },
};

export const DOCTORS_ROUTE = {
  ROOT: {
    URL: "/",
    ICON: "doctors",
    NAME: "Doctors",
    HIDE: false,
  },
  VIEW: {
    URL: "/:id",
    ICON: "create",
    NAME: "View Doctor",
    HIDE: false,
  },
};
export const INVOICE_ROUTE = {
  ROOT: {
    URL: "/",
    ICON: "invoice",
    NAME: "Invoice",
    HIDE: false,
  },
  VIEW: {
    URL: "/:id",
    ICON: "create",
    NAME: "View Invoice",
    HIDE: false,
  },
};


export const PAYMENT_ROUTE = {
  ROOT: {
    URL: "/",
    ICON: "payment",
    NAME: "Payment",
    HIDE: false,
  },
};

export const ORDER_STAGES = {
  ORDER_READY_TO_SHIP: "ORDER_READY_TO_SHIP",
};

export const WORKER_ROLES = [
  {
    label: "Company Manager",
    value: "company_manager",
  },
  {
    label: "Store Manager",
    value: "store_manager",
  },
  {
    label: "Company Supporter",
    value: "company_supporter",
  },
  {
    label: "Company Staff",
    value: "company_staff",
  },
];

export const WORKING_DAY_LIST = [
  {
    label: "Mon",
    value: "monday",
  },
  {
    label: "Tue",
    value: "tuesday",
  },
  {
    label: "Wed",
    value: "wednesday",
  },
  {
    label: "Thu",
    value: "thursday",
  },
  {
    label: "Fri",
    value: "friday",
  },
  {
    label: "Sat",
    value: "saturday",
  },
  {
    label: "Sun",
    value: "sunday",
  },
];

export const WORKING_DAY_SORTER = {
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
  Sat: 6,
  Sun: 7,
};

export const TIMEZONE_LIST = [
  {
    label: "Asia/Bangkok",
    value: "Asia/Bangkok",
  },
];

export const ORDER_TYPE = [
  {
    label: "In house RX delivery",
    value: "in_house_rx_driver",
  },
  {
    label: "In house Pharmacy delivery",
    value: "in_house_company_driver",
  },
  {
    label: "On-Demand Delivery",
    value: "on_demand",
  },
  {
    label: "Shipping",
    value: "shipping",
  },
];

export const STORE_TYPES = [
  {
    label: "In house RX drivers",
    value: "in_house_rx_driver",
  },
  {
    label: "In house company driver",
    value: "in_house_company_driver",
  },
];

export const GENDERS = [
  {
    label: "Male",
    value: "male",
  },
  {
    label: "Female",
    value: "female",
  },
];

export const FILTER_MENU = [
  {
    name: "Request Received",
    value: "REQUEST_RECEIVED",
  },
  {
    name: "Pending Dispatch",
    value: "PENDING_DISPATCH",
  },
  {
    name: "Pending Release",
    value: "PENDING_RELEASE",
  },
  {
    name: "Pending Retry",
    value: "PENDING_RETRY",
  },
  {
    name: "Estimates Received",
    value: "ESTIMATES_RECEIVED",
  },
  {
    name: "Estimates Failed",
    value: "ESTIMATES_FAILED",
  },
  {
    name: "Order Dispatched",
    value: "ORDER_DISPATCHED",
  },
  {
    name: "Order Failed",
    value: "ORDER_FAILED",
  },
  {
    name: "Order Assigned",
    value: "ORDER_ASSIGNED",
  },
  {
    name: "Order Unassigned",
    value: "ORDER_UNASSIGNED",
  },
  {
    name: "Pickup Started",
    value: "PICKUP_STARTED",
  },
  {
    name: "Pickup Completed",
    value: "PICKUP_COMPLETED",
  },
  {
    name: "In Transit",
    value: "IN_TRANSIT",
  },
  {
    name: "Out For Delivery",
    value: "OUT_FOR_DELIVERY",
  },
  {
    name: "Order Delivered",
    value: "ORDER_DELIVERED",
  },
  {
    name: "Order Delayed",
    value: "ORDER_DELAYED",
  },
  {
    name: "Order Redelivery",
    value: "ORDER_REDELIVERY",
  },
  {
    name: "Order Undeliverable",
    value: "ORDER_UNDELIVERABLE",
  },
  {
    name: "Error Exception",
    value: "ERROR_EXCEPTION",
  },
  {
    name: "Order Cancelled",
    value: "ORDER_CANCELLED",
  },
  {
    name: "Partial In Transit",
    value: "PARTIAL_IN_TRANSIT",
  },
  {
    name: "Partial Delivered",
    value: "PARTIAL_DELIVERED",
  },
  {
    name: "Partial Exception",
    value: "PARTIAL_EXCEPTION",
  },
  {
    name: "Partial Cancelled",
    value: "PARTIAL_CANCELLED",
  },
];

export const DATE_FORMAT = "MM/dd/yyyy";

export const DATE_TIME_FORMAT = "EEEE, MM/dd/yyyy hh:mm aaaaa";

export const ORDER_LIST_DATE_TIME_FORMAT = "MM/dd/yy hh:mm a";

export const DATE_OF_BIRTH_FORMAT = "MM/dd/yyyy";

export const ORDER_STATUSES = {
  REQUEST_RECEIVED: { color: "#008DFF", text: "Request Received" },
  PENDING_DISPATCH: { color: "#008DFF", text: "Pending Dispatch" },
  PENDING_RELEASE: { color: "#008DFF", text: "Pending Release" },
  PENDING_RETRY: { color: "#CA8E03", text: "Pending Retry" },
  ESTIMATES_RECEIVED: { color: "#CA8E03", text: "Estimates Received" },
  ESTIMATES_FAILED: { color: "#EF3900", text: "Estimates Failed" },
  ORDER_DISPATCHED: { color: "#008DFF", text: "Order Dispatched" },
  ORDER_FAILED: { color: "#EF3900", text: "Order Failed" },
  ORDER_ASSIGNED: { color: "#041967", text: "Order Assigned" },
  ORDER_UNASSIGNED: { color: "#CA8E03", text: "Order Unassigned" },
  PICKUP_STARTED: { color: "#008DFF", text: "Pickup Started" },
  PICKUP_COMPLETED: { color: "#008DFF", text: "Pickup Completed" },
  IN_TRANSIT: { color: "#008DFF", text: "In Transit" },
  OUT_FOR_DELIVERY: { color: "#EF3900", text: "Out For Delivery" },
  ORDER_DELIVERED: { color: "#041967", text: "Order Delivered" },
  ORDER_DELAYED: { color: "#CA8E03", text: "Order Delayed" },
  ORDER_REDELIVERY: { color: "#CA8E03", text: "Order Redelivery" },
  ORDER_UNDELIVERABLE: { color: "#EF3900", text: "Order Undeliverable" },
  ERROR_EXCEPTION: { color: "#EF3900", text: "Error Exception" },
  ORDER_CANCELLED: { color: "#EF3900", text: "Order Cancelled" },
  PARTIAL_IN_TRANSIT: { color: "#008DFF", text: "Partial In Transit" },
  PARTIAL_DELIVERED: { color: "#041967", text: "Partial Delivered" },
  PARTIAL_EXCEPTION: { color: "#008DFF", text: "Partial Exception" },
  PICKUP_EXCEPTION: { color: "#008DFF", text: "Pickup Exception" },
  PARTIAL_CANCELLED: { color: "#EF3900", text: "Partial Cancelled" },
  EXPIRED: { color: "#EF3900", text: "Expired" },
  RETURNED: { color: "#EF3900", text: "Returned" },
};

export const INVOICE_STATUSES = {
  paid: { color: "#3A4B88", text: "Paid" },
  unpaid: { color: "#D9AC45", text: "Unpaid" },
  not_completed: { color: "#ED6C6C", text: "Not Completed" },
  payment_failed: { color: "#ED6C6C", text: "Payment Failed" },
};

export const PAYMENT_STATUSES = {
  paid: { color: "#3A4B88", text: "Paid" },
  unpaid: { color: "#D9AC45", text: "Unpaid" },
  not_completed: { color: "#ED6C6C", text: "Not Completed" },
};

export const BANK_ACCOUNT_STATUSES = {
  in_progress: { color: "#3A4B88", text: "In Progress" },
  verified: { color: "#008DFF", text: "Verified" },
  failed: { color: "#EF3900", text: "Failed" },
  cancelled: { color: "#EF3900", text: "Cancelled" },
  blocked: { color: "#EF3900", text: "Blocked" },
  expired: { color: "#EF3900", text: "Expired" },
};
export const IMPORT_ORDER_STATUSES = {
  processing: {
    color: "#CA8E03",
    text: "In progress",
    textPrimary: "In progress",
    textSecondary: "In progress",
  },
  failed: {
    color: "#EF3900",
    text: "Error",
    textPrimary: "Error",
    textSecondary: "Fail",
  },
  completed: {
    color: "#008DFF",
    text: "Success",
    textPrimary: "Success",
    textSecondary: "Success",
  },
};
export const PRIOR_STATUSES = {
  NEW_DATA: { color: "#008DFF", text: "New" },
  APPROVED: { color: "#008DFF", text: "approved" },
  CANCELLED: { color: "#EF3900", text: "cancelled" },
  SENT_TO_PLAN: {
    color: "#CA8E03",
    text: "Sent to plan",
    textSecondary: "Unknow",
  },
  SENT_TO_MD: {
    color: "#CA8E03",
    text: "Sent to MD",
    textSecondary: "Unknow",
  },
  DENIED: { color: "#EF3900", text: "Denied" },
  NEED_MORE_INFOR: { color: "#041967", text: "Need More Info" },
  APPEAL: { color: "#041967", text: "Appeal" },
  ON_HOLD: { color: "#CA8E03", text: "On Hold" },
  COMPLETED: { color: "#041967", text: "Completed" },
  DEFERRED: { color: "#EF3900", text: "Deferred", textSecondary: "Unknow" },
};

export const ACTIVE_ORDER_STATUSES = [
  {
    name: "Request Received",
    value: "REQUEST_RECEIVED",
  },
  {
    name: "Pending Dispatch",
    value: "PENDING_DISPATCH",
  },
  {
    name: "Order Dispatched",
    value: "ORDER_DISPATCHED",
  },
  {
    name: "Pickup Started",
    value: "PICKUP_STARTED",
  },
  {
    name: "Order Redelivery",
    value: "ORDER_REDELIVERY",
  },
];

export const COMPLETED_ORDER_STATUSES = [
  {
    name: "Order Dispatched",
    value: "ORDER_DISPATCHED",
  },
  {
    name: "Order Delivered",
    value: "ORDER_DELIVERED",
  },
  {
    name: "Partial Delivered",
    value: "PARTIAL_DELIVERED",
  },
  {
    name: "Order Failed",
    value: "ORDER_FAILED",
  },
];

export const UNDELIVERABLE_ORDER_STATUSES = [
  {
    name: "Out For Delivery",
    value: "OUT_FOR_DELIVERY",
  },
  {
    name: "Order Undeliverable",
    value: "ORDER_UNDELIVERABLE",
  },
  {
    name: "Error Exception",
    value: "ERROR_EXCEPTION",
  },
  {
    name: "Order Cancelled",
    value: "ORDER_CANCELLED",
  },
  {
    name: "Partial Cancelled",
    value: "PARTIAL_CANCELLED",
  },
];

export const ORDER_STATUSES_CAN_CANCEL = [
  "PENDING_DISPATCH",
  "PENDING_RELEASE",
  "ESTIMATES_FAILED",
  "ORDER_FAILED",
  "ORDER_DISPATCHED",
];

export const ORDER_STATUSES_CAN_EDIT = [
  "PENDING_DISPATCH",
  "ESTIMATES_FAILED",
  "ORDER_FAILED",
];

export const INTERCOM_APP_ID = "bz0rsx1g";

export const UPLOAD_ALLOWED_FILES = ["csv"];
export const UPLOAD_MAX_FILE_SIZE = 2000;

export const STATUS_FILTER = {
  COMPLETED: "completed",
  FAILED: "failed",
  PROCESSING: "processing",
};

export const PRIOR_EDIT_STATUS = [
  "new_data",
  "approved",
  "cancelled",
  "sent_to_plan",
  "sent_to_md",
  "denied",
  "need_more_infor",
  "appeal",
  "on_hold",
  "deferred",
];

export const UPLOAD_DOCTOR_ALLOWED_FILES = [
  "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

export const ORDER_EDIT_STATUS = {
  REQUEST_RECEIVED: "REQUEST_RECEIVED",
  ORDER_DISPATCHED: "ORDER_DISPATCHED",
  ORDER_ASSIGNED: "ORDER_ASSIGNED",
  ORDER_UNASSIGNED: "ORDER_UNASSIGNED",
  PICKUP_STARTED: "PICKUP_STARTED",
  PICKUP_COMPLETED: "PICKUP_COMPLETED",
};
