import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";
import {  logEvent } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyBkjioGZV1G8PzQ3UoTlVR260wu5YmviCs",
  authDomain: "rxdeliverednow.firebaseapp.com",
  databaseURL: "https://rxdeliverednow.firebaseio.com",
  projectId: "rxdeliverednow",
  storageBucket: "rxdeliverednow.appspot.com",
  messagingSenderId: "126901363846",
  appId: "1:126901363846:web:915b95dd38f78f27b59d07",
  measurementId: "G-RWG6FHPZ8V"
};

//initializeApp(firebaseConfig);
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const messaging = getMessaging();
export const firebaseAnalytics = getAnalytics(app);


  //console.log(worker);
  //const { id } = worker || {};
  //console.log(id);
export const logCustom = (eventName, defaultStore, worker) => {
  
  return logEvent(firebaseAnalytics, eventName, {
    npi : defaultStore.npi,
    storeId: defaultStore.storeId,
    storeName: defaultStore.storeName,
    workerID : worker.id,
    workerName : worker.first_name + " " + worker.last_name ,
  });

}

export const requestForToken = () => {
  return getToken(messaging, { vapidKey: 'BCofXPj78O_cTpd8ha_KSlJsE-LJUcrFgcUUUf9YWt7oXhOUaXzKR2aLer9No1E5Z19K5cUDngrwrBZKlpKTNR4' }).then((currentToken) => {
    if (currentToken) {
      // Send the token to your server and update the UI if necessary
      // ...
      console.log("currentToken", currentToken)
    } else {
      // Show permission request UI
      console.log('No registration token available. Request permission to generate one.');
      // ...
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // ...
  });
};