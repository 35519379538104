import axios from 'axios';
import queryString from 'query-string';
import { API_PREFIX, API_URL } from 'utils/constants';

const axiosClient = axios.create({
  baseURL: `${API_URL}/${API_PREFIX}/`,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*'
  },
  paramsSerializer: params => queryString.stringify(params),
});

axiosClient.interceptors.request.use(async (config) => {
  // Handle token here ...
  const worker = localStorage.getItem("worker");
  const { isLogged = false, access_token = "" } = JSON.parse(worker) || {};

  if (isLogged) {
    config.headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'AUTH_TOKEN': access_token,
    }
  }
  return config;
});

axiosClient.interceptors.response.use(async (response) => {
  if (response.data) {
    return response.data;
  }

  return response;
}, async (error) => {
  // Handle errors
  const { response = {}, config: originalRequest } = error;
  const { status, data } = response;
  const { errors = [] } = data;
  const { code } = errors.length > 0 ? errors[0] : {};

  if (status === 401 && window.location.pathname !== "/sign-in" && window.location.pathname !== "/login_as") {
    localStorage.removeItem("worker");
    localStorage.removeItem("defaultStore");
    window.location.href = "/sign-in";
  }

  if (status === 400 && code === 1400 && window.location.pathname !== "/sign-in" && window.location.pathname !== "/login_as") {
    localStorage.removeItem("worker");
    localStorage.removeItem("defaultStore");
    window.location.href = "/sign-in";
  }

  // if (status === 401 && !originalRequest._retry && co1e === 1203) {
  //   const workerLocal = localStorage.getItem("worker");
  //   const { access_token = "", refresh_token = "" } = JSON.parse(workerLocal) || {};

  //   originalRequest._retry = true;
  //   const worker = {
  //     refresh_token: refresh_token,
  //   };

  //   const refreshTokenResponse = await workersApi.refreshToken({ worker });
  //   localStorage.setItem(
  //     "worker",
  //     JSON.stringify({ ...refreshTokenResponse, isLogged: true })
  //   );

  //   axios.defaults.headers.common['AUTH_TOKEN'] = access_token;

  //   return axiosClient(originalRequest);
  // }
  return Promise.reject(errors);
});

export default axiosClient;