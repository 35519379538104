import { InfoWindow, Marker } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import SelectedMarker from "../../../../assets/images/selected.svg"
import { styled, Typography } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const InfoWindowData = styled(Typography)(() => ({
    display: "flex",
    justifyContent: "space-between",
    fontSize: 12,
    padding: "0px",
}));

const SelectedAddress = ({ arrivalLong, arrivalLat, recipientName, destinationAddress, routeIndex }) => {
    const [selectedPosition, setSelectedPosition] = useState({
        lat: null,
        lng: null,
    });

    const [activeMarker, setActiveMarker] = useState(routeIndex);

    const handleActiveMarker = (routeIndex) => {
        if (routeIndex === activeMarker) {
            return;
        }
        setActiveMarker(routeIndex);
    };

    useEffect(() => {
        setSelectedPosition({
            lat: parseFloat(arrivalLat),
            lng: parseFloat(arrivalLong),
        })
    }, [arrivalLong, arrivalLat])

    return (
        <>
            <Marker
                position={{
                    lat: parseFloat(arrivalLat),
                    lng: parseFloat(arrivalLong),
                }}
                icon={{
                    url: SelectedMarker, // Path to your GreenMarker icon
                }}
                label={{
                    text: `${routeIndex + 1}`,
                    fontSize: "9px",
                }}
                zIndex={99999999}
                onClick={() => handleActiveMarker(routeIndex)}
            >
                {activeMarker === routeIndex &&
                    selectedPosition.lat !== null &&
                    selectedPosition.lng !== null &&
                    routeIndex !== undefined ? (
                    <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                        <div>
                            <InfoWindowData>
                                <p>{recipientName}</p>
                                <CloseIcon
                                    onClick={() => setActiveMarker(null)}
                                    style={{ cursor: "pointer" }}
                                />
                            </InfoWindowData>
                            <Typography>{destinationAddress}</Typography>
                        </div>
                    </InfoWindow>
                ) : null}
            </Marker>
        </>

    )
}

export default SelectedAddress;