import { useEffect, useState } from "react";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
const { styled, Box } = require("@mui/material");

const DataBox = styled(Box)(({ backgroundColor }) => ({
    borderRadius: "16px",
    padding: "15px",
    margin: "5px",
    backgroundColor: backgroundColor,
}));

const DataTotalInfo = ({ label, data, infoName, backgroundColor, compareData }) => {
    const [status, setStatus] = useState();

    useEffect(() => {
        if (compareData >= 0) {
            setStatus(true);
        } else {
            setStatus(false);
        }
    }, [compareData]);

    const textSize = window.innerWidth <= 600 ? '12px' : '16px';
    const iconSize = window.innerWidth <= 600 ? '16px' : '20px';
    const textSizeMain = window.innerWidth <= 600 ? '16px' : '28px';

    return (
        <DataBox className="data-box-info" backgroundColor={backgroundColor}>
            <p style={{ margin: '0px', fontSize: textSize }}>{label}</p>
            <h1 style={{ margin: '0px', color: '#041967', fontSize: textSizeMain }}>{data} {infoName}</h1>
            {data !== 0 && (
                <div>
                    {status ? (
                        <div style={{ display: "flex", color: "green" }}>
                            <ArrowUpwardIcon style={{ fontSize: iconSize }} />
                            <p style={{ margin: '0px', fontSize: textSize }}>{compareData}% vs last 7 days</p>
                        </div>
                    ) : (
                        <div style={{ display: "flex", color: "red" }}>
                            <ArrowDownwardIcon style={{ fontSize: iconSize }} />
                            <p style={{ margin: '0px', fontSize: textSize }}>{compareData}% vs last 7 days</p>
                        </div>
                    )}
                </div>
            )}
        </DataBox>
    )
}

export default DataTotalInfo