import { Box, styled, Typography } from "@mui/material";
import { useDefaultStore } from "hooks/useDefaultStore";
import { ReactComponent as DownSVG } from "assets/images/icons/down.svg";
import { useState } from "react";
import SelectStoreModal from "components/Modal/SelectStore";

const BoxStoreName = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    marginRight: 18,
    cursor: "pointer",
}));

const StoreNameTypo = styled(Typography)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    fontSize: 16,
    color: theme.palette.primary.main,
    fontWeight: 500,
}));

const StoreSelector = () => {
    const defaultStore = useDefaultStore();
    const { name: storeName, id: storeId } = defaultStore || {};
    const [isOpenSelectStoreModal, setIsOpenSelectStoreModal] = useState(false);

    const onClickOpenSelectStoreModal = () => {
        setIsOpenSelectStoreModal(true);
    };

    const onCloseSelectStoreModal = () => {
        setIsOpenSelectStoreModal(false);
    };

    return (
        <>
            {storeName && (
                <BoxStoreName onClick={onClickOpenSelectStoreModal}>
                    <StoreNameTypo>
                        {storeId === "all" ? "Select default store" : storeName}{" "}
                        <DownSVG />
                    </StoreNameTypo>
                </BoxStoreName>
            )}

            {!storeName && (
                <BoxStoreName onClick={onClickOpenSelectStoreModal}>
                    <StoreNameTypo>
                        Select default store <DownSVG />
                    </StoreNameTypo>
                </BoxStoreName>
            )}

            {isOpenSelectStoreModal && (
                <SelectStoreModal
                    isOpen={isOpenSelectStoreModal}
                    onClose={onCloseSelectStoreModal}
                />
            )}
        </>
    )
}

export default StoreSelector;