import { useWorker } from "hooks/useWorker";
import React, { Suspense } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ORDERS_ROUTE, OTC_ORDERS_ROUTE, ROUTE } from "utils/constants";
import OtcOrderList from "./OtcOrderList";


import LoadingComponent from "components/Loading";
function PrivateRoute({ children }) {
  const workerStore = useWorker() || {};
  const { isLogged = false } = workerStore || {};
  const location = useLocation();
  return isLogged ? (
    children
  ) : (
    <Navigate to={ROUTE.SIGN_IN.URL} state={{ from: location }} />
  );
}

const OtcOrders = () => {
  return (
    <Suspense fallback={<LoadingComponent />}>
      <Routes>
        <Route
          path={OTC_ORDERS_ROUTE.ROOT.URL}
          element={
            <PrivateRoute>
              <OtcOrderList />
            </PrivateRoute>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default React.memo(OtcOrders);
