import { Avatar, Box, ListItemIcon, ListItemText, MenuItem, MenuList, Popover, styled, Typography } from "@mui/material";
import { ReactComponent as PasswordSVG } from "assets/images/icons/password.svg";
import { ReactComponent as SignOutRedSVG } from "assets/images/icons/sign_out_red.svg";
import { ReactComponent as DownSVG } from "assets/images/icons/down.svg";
import { useWorker } from "hooks/useWorker";
import { useState } from "react";
import { ROUTE, WORKER_ROLES } from "utils/constants";
import workersApi from "apis/workers";
import { useDispatch } from "react-redux";
import { signOut } from "redux/actions/worker";
import { clear } from "redux/actions/defaultStore";
import { useNavigate } from "react-router-dom";
import ChangePassword from "components/Modal/ChangePassword";

const BoxWorkerAvatar = styled(Box)(({ theme }) => ({
    marginLeft: 32,
    display: "flex",
    alignItems: "center",
    position: "relative",
    "&::before": {
        content: '""',
        borderLeft: `1px solid ${theme.palette.grey.main}`,
        width: 1,
        height: 15,
        position: "absolute",
        left: -32,
        display: "flex",
        textAlign: "center",
    },
}));

const BoxWorkerRole = styled(Box)(({ theme }) => ({
    marginLeft: 8,
    marginRight: 16,
}));

const WorkerNameTypo = styled(Typography)(({ theme }) => ({
    color: theme.palette.blue.main,
    fontSize: 16,
    fontWeight: 600,
}));

const WorkerRoleTypo = styled(Typography)(({ theme }) => ({
    fontSize: 12,
    color: theme.palette.grey.main,
    fontWeight: 500,
}));

const BoxWorkerDown = styled(Box)(({ theme }) => ({
    cursor: "pointer",
}));

const BoxWorkerActionsPopover = styled(Box)(({ theme, loading }) => ({
    maxWidth: 300,
    position: "relative",
    background: loading ? "#F1F1F1" : "transparent",
    opacity: loading ? "0.7" : "1",
}));

const stringAvatar = (name) => {
    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
};

const stringToColor = (string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
};

const WorkerAvatar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const worker = useWorker();
    const { first_name, last_name, roles = [], avatar } = worker || {};
    const roleDisplay = roles
        .map(
            (role) => WORKER_ROLES.find((constRole) => constRole.value === role).label
        )
        .join(",");
    const [workerActionsEle, setWorkerActionEle] = useState(null);
    const [isOpenWorkerActionsPopover, setIsOpenWorkerActionsPopover] = useState(false);
    const [isOpenChangePasswordModal, setIsOpenChangePasswordModal] = useState(false);
    
    const onOpenWorkerActionsPopover = (event) => {
        setWorkerActionEle(event.currentTarget);
        setIsOpenWorkerActionsPopover(true);
    };

    const onCloseWorkerActionsPopover = () => {
        setWorkerActionEle(null);
        setIsOpenWorkerActionsPopover(false);
    };

    const onClickOpenChangePasswordModal = () => {
        setIsOpenChangePasswordModal(true);
        onCloseWorkerActionsPopover();
    };

    const onSignOut = async () => {
        try {
            await workersApi.signOut();
            localStorage.removeItem("worker");
            localStorage.removeItem("defaultStore");
            dispatch(signOut(true));
            dispatch(clear(true));
            navigate(ROUTE.SIGN_IN.URL, { replace: false });
            onCloseWorkerActionsPopover();
        } catch (error) { }
    };

    return (
        <div>
            <BoxWorkerAvatar>
                {avatar && avatar !== "" ? (
                    <Avatar src={avatar} />
                ) : (
                    <Avatar
                        {...stringAvatar(
                            `${first_name && first_name.replace(" ", "")} ${last_name && last_name.replace(" ", "")
                            }`
                        )}
                    />
                )}

                <BoxWorkerRole>
                    <WorkerNameTypo>
                        {`${first_name} ${last_name}`}
                    </WorkerNameTypo>
                    <WorkerRoleTypo>{roleDisplay}</WorkerRoleTypo>
                </BoxWorkerRole>
                <BoxWorkerDown
                    onClick={onOpenWorkerActionsPopover}
                    aria-describedby="worker-actions-id"
                >
                    <DownSVG />
                </BoxWorkerDown>
            </BoxWorkerAvatar>

            {isOpenWorkerActionsPopover && (
                <Popover
                    id="worker-actions-id"
                    open={isOpenWorkerActionsPopover}
                    anchorEl={workerActionsEle}
                    onClose={onCloseWorkerActionsPopover}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                >
                    <BoxWorkerActionsPopover>
                        <MenuList>
                            <MenuItem onClick={onClickOpenChangePasswordModal}>
                                <ListItemIcon>
                                    <PasswordSVG />
                                </ListItemIcon>
                                <ListItemText>Change Password</ListItemText>
                            </MenuItem>
                            <MenuItem onClick={onSignOut}>
                                <ListItemIcon>
                                    <SignOutRedSVG />
                                </ListItemIcon>
                                <ListItemText style={{ color: "#EF3900" }}>
                                    Logout
                                </ListItemText>
                            </MenuItem>
                        </MenuList>
                    </BoxWorkerActionsPopover>
                </Popover>
            )}

            {isOpenChangePasswordModal && (
                <ChangePassword
                    isOpen={isOpenChangePasswordModal}
                    onClose={() => {
                        setIsOpenChangePasswordModal(false);
                    }}
                    onConfirm={() => {
                        onSignOut();
                        setIsOpenChangePasswordModal(false);
                    }}
                />
            )}
        </div>
    )
}

export default WorkerAvatar;