import { API_V1, PREFIX_PHARMACY, PREFIX_WORKERS } from "utils/constants";
import axiosClient from "./apiClient";

const workersApi = {

  logInAs: async (params) => {
    try {
      const url = `${API_V1}/${PREFIX_PHARMACY}/login_as`;
      const response = await axiosClient.post(url, params);
      if (response.success) {
        return response.data;
      } else {
        throw response;
      }
    } catch (error) {
      console.log(error)
    }
  },

  signIn: async (params) => {
    try {
      const url = `${API_V1}/${PREFIX_PHARMACY}/login`;
      const response = await axiosClient.post(url, params);
      if (response.success) {
        return response.data;
      } else {
        throw response;
      }
    } catch (error) {
      if (Array.isArray(error) && error.length > 0) {
        const errors = error.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.field || "message"]: item.message }),
          {}
        );
        throw errors;
      }
      throw error.data;
    }
  },
  signUp: async (params) => {
    try {
      const url = `${API_V1}/${PREFIX_PHARMACY}/signup`;
      const response = await axiosClient.post(url, params);
      if (response.success) {
        return response.data;
      } else {
        return response;
      }
    } catch (error) {
      if (Array.isArray(error) && error.length > 0) {
        const errors = error.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.field || "message"]: item.message }),
          {}
        );
        throw errors;
      }
      throw error.data;
    }
  },
  registrations: async (params) => {
    try {
      const url = `${API_V1}/${PREFIX_PHARMACY}/registrations/verify_user_information`;
      const response = await axiosClient.post(url, params);
      if (response.success) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      if (Array.isArray(error) && error.length > 0) {
        const errors = error.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.field || "message"]: item.message }),
          {}
        );
        throw errors;
      }
      throw error.data;
    }
  },
  verifyCompany: async (params) => {
    try {
      const url = `${API_V1}/${PREFIX_PHARMACY}/registrations/verify_company_information`;
      const response = await axiosClient.post(url, params);
      if (response.success) {
        return response;
      } else {
        return response;
      }
    } catch (error) {
      if (Array.isArray(error) && error.length > 0) {
        const errors = error.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.field || "message"]: item.message }),
          {}
        );
        throw errors;
      }
      throw error.data;
    }
  },
  forgetPassword: async (params) => {
    try {
      const url = `${API_V1}/${PREFIX_PHARMACY}/forget_password`;
      const response = await axiosClient.post(url, params);
      if (response === "ok") {
        return response;
      }
      if (!response.success) {
        throw response;
      }
    } catch (error) {
      if (Array.isArray(error) && error.length > 0) {
        const errors = error.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.field || "message"]: item.message }),
          {}
        );
        throw errors;
      }
      if (Array.isArray(error.errors) && error.errors.length > 0) {
        const errors = error.errors.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.field || "message"]: item.message }),
          {}
        );
        throw errors;
      }
      throw error;
    }
  },
  resetPassword: async (params) => {
    try {
      const url = `${API_V1}/${PREFIX_PHARMACY}/reset_passwords`;
      const response = await axiosClient.patch(url, params);
      if (response === "ok") {
        return response;
      }
      if (!response.success) {
        throw response;
      }
    } catch (error) {
      if (Array.isArray(error) && error.length > 0) {
        const errors = error.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.field || "message"]: item.message }),
          {}
        );
        throw errors;
      }
      if (Array.isArray(error.errors) && error.errors.length > 0) {
        const errors = error.errors.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.field || "message"]: item.message }),
          {}
        );
        throw errors;
      }
      throw error;
    }
  },
  signOut: async () => {
    try {
      const url = `${API_V1}/${PREFIX_PHARMACY}/logout`;
      const response = await axiosClient.delete(url);
      if (response === "ok") {
        return response;
      }
      if (!response.success) {
        throw response;
      }
    } catch (error) {
      if (Array.isArray(error.data?.errors) && error.data?.errors.length > 0) {
        const errors = error.data.errors.reduce(
          (obj, item) => Object.assign(obj, { message: item.message }),
          {}
        );
        throw errors;
      }
      throw error;
    }
  },
  refreshToken: async (params) => {
    try {
      const url = `${API_V1}/${PREFIX_PHARMACY}/refresh_token`;
      const response = await axiosClient.post(url, params);
      return response.data;
    } catch (error) {
      throw error.data;
    }
  },
  getList: async (params) => {
    try {
      const url = `${API_V1}/${PREFIX_PHARMACY}/${PREFIX_WORKERS}`;
      const response = await axiosClient.get(url, { params });
      return response;
    } catch (error) {
      throw error.data;
    }
  },
  add: async (params, npi) => {
    try {
      const url = `${API_V1}/${PREFIX_PHARMACY}/${PREFIX_WORKERS}`;
      const response = await axiosClient.post(url, params, { params: { npi } });
      if (response.success) {
        return response;
      } else {
        throw response;
      }
    } catch (error) {
      if (Array.isArray(error) && error.length > 0) {
        const errors = error.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.field || "message"]: item.message }),
          {}
        );
        throw errors;
      }
      if (Array.isArray(error.errors) && error.errors.length > 0) {
        const errors = error.errors.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.field || "message"]: item.message }),
          {}
        );
        throw errors;
      }
      throw error;
    }
  },
  update: async (params, id) => {
    try {
      const url = `${API_V1}/${PREFIX_PHARMACY}/${PREFIX_WORKERS}/${id}`;
      const response = await axiosClient.patch(url, params);
      if (response.success) {
        return response.data;
      } else {
        throw response;
      }
    } catch (error) {
      if (Array.isArray(error) && error.length > 0) {
        const errors = error.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.field || "message"]: item.message }),
          {}
        );
        throw errors;
      }
      if (Array.isArray(error.errors) && error.errors.length > 0) {
        const errors = error.errors.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.field || "message"]: item.message }),
          {}
        );
        throw errors;
      }
      throw error;
    }
  },
  changeRolesWorker: async (params, id) => {
    try {
      const url = `${API_V1}/${PREFIX_PHARMACY}/${PREFIX_WORKERS}/${id}/roles`;
      const response = await axiosClient.patch(url, params);
      if (response.success) {
        return response;
      } else {
        throw response;
      }
    } catch (error) {
      if (Array.isArray(error) && error.length > 0) {
        const errors = error.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.field || "message"]: item.message }),
          {}
        );
        throw errors;
      }
      if (Array.isArray(error.errors) && error.errors.length > 0) {
        const errors = error.errors.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.field || "message"]: item.message }),
          {}
        );
        throw errors;
      }
      throw error;
    }
  },
  changePassword: async (params) => {
    try {
      const url = `${API_V1}/${PREFIX_PHARMACY}/${PREFIX_WORKERS}/change_password`;
      const response = await axiosClient.patch(url, params);
      if (response.success) {
        return response;
      } else {
        throw response;
      }
    } catch (error) {
      if (Array.isArray(error) && error.length > 0) {
        const errors = error.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.field || "message"]: item.message }),
          {}
        );
        throw errors;
      }
      if (Array.isArray(error.errors) && error.errors.length > 0) {
        const errors = error.errors.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.field || "message"]: item.message }),
          {}
        );
        throw errors;
      }
      throw error;
    }
  },
  appConfigs: async () => {
    try {
      const url = `${API_V1}/${PREFIX_PHARMACY}/app_configs`;
      const response = await axiosClient.get(url);
      if (response.success) {
        return response.data;
      } else {
        throw response;
      }
    } catch (error) {
      throw error.data;
    }
  },
  changeStatus: async (id) => {
    try {
      const url = `${API_V1}/${PREFIX_PHARMACY}/${PREFIX_WORKERS}/${id}/change_status`;
      const response = await axiosClient.patch(url);
      if (response.success) {
        return response;
      } else {
        throw response;
      }
    } catch (error) {
      if (Array.isArray(error) && error.length > 0) {
        const errors = error.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.field || "message"]: item.message }),
          {}
        );
        throw errors;
      }
      if (Array.isArray(error.errors) && error.errors.length > 0) {
        const errors = error.errors.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.field || "message"]: item.message }),
          {}
        );
        throw errors;
      }
      throw error;
    }
  },
  getDetail: async (id) => {
    try {
      const url = `${API_V1}/${PREFIX_PHARMACY}/${PREFIX_WORKERS}/${id}`;
      const response = await axiosClient.get(url);
      if (response.success) {
        return response.data;
      } else {
        throw response;
      }
    } catch (error) {
      if (Array.isArray(error) && error.length > 0) {
        const errors = error.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.field || "message"]: item.message }),
          {}
        );
        throw errors;
      }
      if (Array.isArray(error.errors) && error.errors.length > 0) {
        const errors = error.errors.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.field || "message"]: item.message }),
          {}
        );
        throw errors;
      }
      throw error;
    }
  },
  uploadAvatar: async (params) => {
    try {
      const url = `${API_V1}/${PREFIX_PHARMACY}/${PREFIX_WORKERS}/upload_avatar`;
      const response = await axiosClient.patch(url, params);
      if (response.success) {
        return response.data;
      } else {
        throw response;
      }
    } catch (error) {
      if (Array.isArray(error) && error.length > 0) {
        const errors = error.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.field || "message"]: item.message }),
          {}
        );
        throw errors;
      }
      if (Array.isArray(error.errors) && error.errors.length > 0) {
        const errors = error.errors.reduce(
          (obj, item) =>
            Object.assign(obj, { [item.field || "message"]: item.message }),
          {}
        );
        throw errors;
      }
      throw error;
    }
  },
};

export default workersApi;