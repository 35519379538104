import axios from 'axios';
import { DRIVER_API_URL } from 'utils/constants';

const axiosDriver = axios.create({
    baseURL: `${DRIVER_API_URL}/api/`,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
});

export async function getAllDrivers(npi, start, from) {
    try {
        const response = await axiosDriver.post("rx/get-drivers",
            {
                npi,
                start,
                from,
            }
        );
        return response.data;
    } catch (error) {
        console.log(error);
        throw new Error("Error fetching drivers");
    }
}

export async function getAllReports(npi, from, to) {
    try {
        const response = await axiosDriver.post("rx/get-report-pharmacy-delivery",
            {
                npi,
                from,
                to,
            }
        );
        return response.data;
    } catch (error) {
        console.log(error);
        throw new Error("Error fetching drivers");
    }
}

export async function getAllTasks(worker_id, npi, start, from) {
    try {
        const response = await axiosDriver.post("rx/get-tasks",
            {
                worker_id,
                npi,
                start,
                from,
            }
        );
        
        return response.data;
    } catch (error) {
        console.log(error);
        throw new Error("Error fetching drivers");
    }
}