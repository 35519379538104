import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar/Sidebar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import { useLocation } from "react-router-dom";
import { ROUTE } from "utils/constants";

const Layout = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isPop, setIsPop] = useState(false);

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    if (window.innerWidth <= 600) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, []);
  
  const onClickDrawerOpen = () => {
    setIsOpen(!isOpen);
  };

  const onClickPopupOpen = () => {
    setIsPop(true);
  }

  const handlePopupClose = () => {
    setIsPop(false);
  }

  const isExtendLayout =
    pathname !== ROUTE.SIGN_IN.URL &&
    pathname !== ROUTE.SIGN_UP.URL &&
    pathname !== ROUTE.RESET_PASSWORD.URL &&
    pathname !== "/404";

  return (
    <>
      {isExtendLayout ? (
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <Header onClickPopupOpen={onClickPopupOpen} onClickDrawerOpen={onClickDrawerOpen} isOpen={isOpen} />
          <Sidebar handlePopupClose={handlePopupClose} isOpen={isOpen} isPop={isPop} />
          <Box
            component="main"
            sx={{ flexGrow: 1, p: 3 }}
            style={{ overflow: "auto" }}
          >
            <Toolbar style={{ minHeight: 100 }} />
            {children}
          </Box>
        </Box>
      ) : (
        children
      )}
    </>
  );
};

export default React.memo(Layout);
