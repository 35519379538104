import { Marker, Polyline } from "@react-google-maps/api";
import DirectionsFlightPath from "./DirectionsFlightPath";
import { useEffect, useState } from "react";
import DriverPositionMarker from "../../../../assets/images/driver_position.svg"

const SelectedDriverPath = ({ driverPosition, tasksInfo }) => {
    const [pairs, setPairs] = useState();
    const [firstTask, setFirstTask] = useState();

    useEffect(() => {
        const newPairs = [];
        if (tasksInfo !== null) {
            for (let i = 0; i < tasksInfo.length - 1; i++) {
                newPairs.push({
                    current: {
                        lat: parseFloat(tasksInfo[i].arrival_lat),
                        lng: parseFloat(tasksInfo[i].arrival_long), // Changed 'long' to 'lng'
                    },
                    next: {
                        lat: parseFloat(tasksInfo[i + 1].arrival_lat),
                        lng: parseFloat(tasksInfo[i + 1].arrival_long), // Changed 'long' to 'lng'
                    },
                });
            }
            setFirstTask(tasksInfo[0]);
        }
        setPairs(newPairs);
    }, [tasksInfo]);

    return (
        <div>
            {/* Used to draw path from storage to first task and load all marker, 
            also used to load info window  */}
            {tasksInfo && tasksInfo.map && tasksInfo.map((task, index) => (
                <DirectionsFlightPath key={task.id}
                    task={task}
                    taskIndex={index}
                />
            ))}

            {/* If there is driver's current data and they have at least 1 task*/}
            {driverPosition.length > 0 && firstTask && (
                <>
                    <Marker
                        position={{
                            lng: parseFloat(driverPosition[0]),
                            lat: parseFloat(driverPosition[1]),
                        }}
                        icon={{
                            url: DriverPositionMarker,
                        }}
                    >
                    </Marker>

                    {firstTask.arrival_lat && firstTask.arrival_long && (
                        <Polyline
                            path={
                                [
                                    { lat: parseFloat(driverPosition[1]), lng: parseFloat(driverPosition[0]) },
                                    { lat: parseFloat(firstTask.arrival_lat), lng: parseFloat(firstTask.arrival_long) }
                                ]
                            }
                            options={{
                                strokeColor: "#041967",
                                //strokeOpacity: 0.5,
                                strokeWeight: 2,
                                geodesic: true,
                                icons: [{
                                    // eslint-disable-next-line no-undef
                                    icon: { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
                                    offset: '50%'
                                }]
                            }}
                        />
                    )}
                </>
            )}

            {/* Draw line for all current exist task */}
            {pairs && pairs.map && pairs.map((position, index) => (
                <Polyline
                    key={index}
                    path={
                        [
                            { lat: position.current.lat, lng: position.current.lng },
                            { lat: position.next.lat, lng: position.next.lng }
                        ]
                    }
                    options={{
                        strokeColor: "#041967",
                        //strokeOpacity: 0.5,
                        strokeWeight: 2,
                        geodesic: true,
                        icons: [{
                            // eslint-disable-next-line no-undef
                            icon: { path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW },
                            offset: '50%'
                        }]
                    }}
                />
            ))}
        </div>
    )
}

export default SelectedDriverPath;